<template>
  <div>
    <AppBar :title="id ? $t('cashiers.edit') : $t('cashiers.add')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text>
        <v-form ref="addCashier" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :label="$t('users.name')"
                :rules="required"
              ></v-text-field>
              <v-text-field
                v-model="surname"
                :label="$t('users.surname')"
                :rules="required"
              ></v-text-field>
              <v-text-field
                v-model="email"
                :label="$t('users.email')"
                :rules="emailRules"
                required
                :disabled="!!id"
              ></v-text-field>
              <v-select v-model="center" :items="centers" item-text="name" item-value="id" :label="$t('cashiers.shoppingCenter')" :rules="required"></v-select>
              <v-select v-if="countriesList" v-model="assignedCountry" :items="countriesList" item-text="name" item-value="name" :label="$t('users.country')" :rules="required"></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="2">
            <v-btn v-if="id" depressed color="error" @click="remove" :disabled="loading" class="ml-auto" rounded>{{ $t('cashiers.delete') }}</v-btn>
          </v-col>
          <v-col cols="12" offset-md="2" md="1">
            <v-btn depressed color="secondary" @click="save" :disabled="loading" :loading="loading" block rounded>{{ $t('buttons.save') }}</v-btn>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn depressed class="ml-4" @click="close" :disabled="loading" block rounded>{{ $t('buttons.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <Confirm ref="confirm"></Confirm>
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
import Confirm from '../../components/dialogs/Confirm.vue'

export default {
  components: { Confirm },
  name: 'AddCashier',
  data () {
    return {
      loading: false,
      valid: true,
      id: this.$route.params.id,
      name: '',
      surname: '',
      email: '',
      assignedCountry: null,
      role: 3,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      emailRules: [
        (v) => !!v.trim() || this.$t('validation.fieldRequired'),
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
          this.$t('validation.incorrectEmailAddress')
      ],
      centers: [],
      center: null
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getCashier()
    }
    await this.getCenters()
  },
  computed: {
    countriesList () {
      const countries = this.$store.getters.getCountries
      if (this.userRoleId === '99' || this.userRoleId === '100') {
        return [{ id: 1234, name: 'Global' }, ...countries]
      } else if (this.userRoleId === '1') {
        console.log('yere')
        const tempCountries = countries.filter(country => country.name === this.userCountry)
        return tempCountries
      } else {
        return [{ id: 1234, name: 'Global' }, ...countries]
      }
    },
    userRoleId () {
      return this.$store.getters.userRoleId
    },
    userCountry () {
      return this.$store.getters.getCountry
    }
  },
  methods: {
    async getCashier () {
      this.loading = true

      try {
        const { data } = await Service.getById('Employees/Cashier', this.$route.params.id)
        this.name = data.name
        this.surname = data.surname
        this.email = data.email
        this.role = data.role.id
        this.center = data.shoppingCenterId
        this.assignedCountry = data.countryCode
      } catch (error) {}

      this.loading = false
    },
    async getCenters () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/ShoppingCenters/Management?countryCode=${countryCode}`)
        this.centers = data
      } catch (error) {}
      this.loading = false
    },
    async save () {
      this.loading = true
      this.$refs.addCashier.validate()
      if (!this.valid) return

      try {
        if (this.id) {
          await Service.put(`/Employees/${this.$route.params.id}`, {
            name: this.name,
            surname: this.surname,
            role: this.role,
            shoppingCenterId: this.center,
            assignedCountry: this.assignedCountry
          })
        } else {
          await Service.post('/Employees', {
            name: this.name,
            surname: this.surname,
            email: this.email,
            role: this.role,
            shoppingCenterId: this.center,
            assignedCountry: this.assignedCountry
          })
        }

        this.$refs.addCashier.reset()
        this.$router.push({ path: '/cashiers' })
      } catch (error) {}

      this.loading = false
    },
    async remove () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('cashiers.removeCashierAccount'), `${this.$t('cashiers.removeUserAccountConfirm')} ${this.name} ${this.surname}?`)) {
        try {
          await Service.delete('Employees', this.id)

          this.$refs.addCashier.reset()
          this.$router.push({ path: '/cashiers' })
        } catch (error) {}
      }

      this.loading = false
    },
    close () {
      this.$router.push({ path: '/cashiers' })
    }
  }
}
</script>
